import { useState, useEffect, useRef, useCallback } from "react";
import backgroundimg from "../Images/banner-bg.jpg";
import emailjs from "emailjs-com";
import { countriesList } from "../utility/constants";
import { useMediaQuery } from "@material-ui/core";
import { Helmet } from "react-helmet";
import AddAlarmIcon from "@mui/icons-material/AddAlarm";
import AddCardIcon from "@mui/icons-material/AddCard";
import LuggageIcon from "@mui/icons-material/Luggage";
import PeopleIcon from "@mui/icons-material/People";
import ApartmentIcon from "@mui/icons-material/Apartment";
import WheelchairPickupIcon from "@mui/icons-material/WheelchairPickup";
import internationalpic from "../Images/home/interrem.png";
import worldgif from "../Images/home/output-onlinegiftools.gif";
import CircularProgress from "@material-ui/core/CircularProgress";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import whatsapp from "../Images/whatsapp.png";
import { medicals } from "./config";
import NukaCarousel from "../tools/NukaCarousel";
import { faqs } from "./config";
import { Inter } from "./config";
// import docgirl from "../Images/home/medicbot.png";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "../Css/international.css";
import ReCAPTCHA from "react-google-recaptcha";
import CenteredAlert from "./CenteredAlert";
import { revolutionizes } from "./config";
import {
  TextField,
  Button,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  FormHelperText,
  Select,
  Grid,
} from "@material-ui/core";
import Treatmentmini from "./Treatmentmini";
// import Videopart2 from "./Videopart2";

const useStyles = makeStyles((theme) => ({
  button: {
    // margin: theme.spacing(2, 0),
    backgroundColor: "#008b91",
    marginTop: "2rem",
    "&:hover": {
      // Remove hover effect
      backgroundColor: "#02585c",
    },
  },
  arrowIcon: {
    position: "absolute",
    top: "70px",
    left: theme.spacing(2),
    cursor: "pointer",
  },
  nextButton: {
    backgroundColor: "#008b91",
    marginTop: "2rem",
    color: "#fff",
    "&:hover": {
      // Remove hover effect
      backgroundColor: "#02585c",
    },
    "&:disabled": {
      backgroundColor: "#ccc",
      color: "#666",
    },
  },
  recaptcha: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  header: {
    fontSize: "41px",
    marginTop: (props) =>
      props.isMobile ? "69px" : props.step === 1 ? "-293px" : "-362px",

    color: "#fff",
    fontWeight: 600,
    marginBottom: theme.spacing(4),
    textAlign: "center",
  },
}));

const Internationalpage = () => {
  const [loader, setLoader] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [phoneCode, setPhoneCode] = useState("+91");
  const [countryname, setCountryname] = useState("India");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("male");
  const [know, setKnow] = useState("");
  const [country, setCountry] = useState("India");
  const [city, setCity] = useState("");
  const isMobile = useMediaQuery("(max-width:710px)");
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false); // State for managing loading state
  const [expandedQuestions, setExpandedQuestions] = useState([]);
  const [step, setStep] = useState(1);
  const [isStep1Valid, setIsStep1Valid] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [preferredHospital, setPreferredHospital] = useState("");
  const [preferredLocation, setPreferredLocation] = useState("");
  const classes = useStyles({ step, isMobile });
  const contactRef = useRef(null);

  const scrollToContact = () => {
    if (contactRef.current) {
      const targetPosition =
        contactRef.current.offsetTop - (isMobile ? 70 : 100); // Adjust the offset for mobile/desktop
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  };
  const isValidPhoneNumber = (phoneNumber) => {
    const numericPhoneNumber = phoneNumber.replace(/\D/g, "");
    return numericPhoneNumber.length === 10 && /^\d+$/.test(numericPhoneNumber);
  };
  const showAlerts = () => {
    setShowAlert(true);
    resetForm();
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setMessage("");
    setAge("");
    setGender("");
    setKnow("");
    setPhone("");
    setCountry("");
    setCity("");
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    if (!recaptchaValue) {
      alert("Please complete the reCAPTCHA");
      return;
    }

    setLoading(true);
    const serviceid = "service_7yxtqsn";
    const templateid = "template_x3wusd7";
    const publickey = "4UpyJJj3P5JGeMZFz";
    const messageContent = `
    Patient Name: ${name}
    Age: ${age}
    Gender: ${gender}
    Phone Number: ${phoneCode}${phone}
    Email: ${email}
    Country: ${country}
    City: ${city}
    Chief Complaints: ${message}
  `;

    const templateparams = {
      from_name: name,
      from_email: email,
      to_name: "web",
      message: messageContent,
    };

    emailjs
      .send(serviceid, templateid, templateparams, publickey)
      .then((response) => {
        setLoading(false);
        console.log("email set successfully", response);
        showAlerts();
      })
      .catch((error) => {
        setLoading(false);
        console.log("error sending email", error);
      });
  };
  const handlePhoneCodeChange = (event) => {
    setPhoneCode(event.target.value);
  };
  const handleRecaptchaChange = useCallback((value) => {
    setRecaptchaValue(value);
  }, []);

  const toggleQuestion = (id) => {
    if (expandedQuestions.includes(id)) {
      setExpandedQuestions(expandedQuestions.filter((qid) => qid !== id));
    } else {
      setExpandedQuestions([...expandedQuestions, id]);
    }
  };

  useEffect(() => {
    const isValid =
      name.trim() !== "" &&
      age.trim() !== "" &&
      gender !== "" &&
      message.trim() !== "";
    setIsStep1Valid(isValid);
  }, [name, age, gender, message]);

  const titlle =
    "FREE Treatment Advice - Consult for Medine Treatment Advice - Allopathy, Homeopathy, Ayurveda, Unani, Sowa-Rigpa, Siddha, Yoga, Reki, Naturopathy, Meditation, Physiotherapy, Mental Health | RxOne";
  const desc =
    "Get free treatment advice and consult for medical treatments including Allopathy, Homeopathy, Ayurveda, Unani, Sowa-Rigpa, Siddha, Yoga, Reki, Naturopathy, Meditation, Physiotherapy, and Mental Health at RxOne.";

  const handleBack = () => {
    setStep(1);
  };
  const handleNext = () => {
    if (isStep1Valid) {
      setStep(2);
    }
  };

  const renderStep1 = () => (
    <>
      <TextField
        id="firstName"
        name="first_name"
        label="Patient Name"
        variant="filled"
        style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
        required
        value={name}
        onChange={(e) => setName(e.target.value)}
        autoFocus
        fullWidth
        className={classes.nameField}
        InputProps={{
          style: {
            backgroundColor: "#fff", // Set the background color here
          },
          inputProps: {
            style: { marginTop: "7px", marginBottom: "-3px" }, // Add padding to the left side of the input text
          },
        }}
      />
      <TextField
        id="age"
        name="age"
        label="Patient Age"
        variant="filled"
        value={age}
        onChange={(e) => setAge(e.target.value)}
        style={{ marginBottom: "20px", backgroundColor: "white" }}
        fullWidth
        className={classes.nameField}
        onKeyPress={(e) => {
          // Allow only digits (0-9)
          const allowedChars = /^[0-9]*$/;
          if (!allowedChars.test(e.key)) {
            e.preventDefault();
          }
        }}
        inputProps={{
          maxLength: 3, // Optional: Limits the maximum length to 3 digits
        }}
        InputProps={{
          style: {
            backgroundColor: "#fff",
            marginTop: "7px",
            marginBottom: "-3px", // Set the background color here
          },
        }}
      />
      <FormControl
        variant="filled"
        style={{ marginBottom: "20px" }}
        fullWidth
        className={classes.nameField}
        classes={{ root: classes.root }} // Apply the custom styles
      >
        <InputLabel id="gender">Gender</InputLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={gender}
          onChange={(e) => setGender(e.target.value)}
          classes={{ root: classes.root }} // Apply the custom styles
        >
          <MenuItem value="male">Male</MenuItem>
          <MenuItem value="female">Female</MenuItem>
          <MenuItem value="other">Other</MenuItem>
        </Select>
      </FormControl>

      <TextField
        multiline
        maxRows={4}
        id="chiefComplaints"
        name="message"
        label="Describe current medical problem"
        variant="filled"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        required
        fullWidth
        style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
        className={classes.nameField}
        InputProps={{
          style: {
            backgroundColor: "#fff", // Set the background color here
          },
          inputProps: {
            style: { marginTop: "7px", marginBottom: "-3px" }, // Add padding to the left side of the input text
          },
        }}
      />

      <TextField
        label="Preferred Hospital (Optional)"
        value={preferredHospital}
        onChange={(e) => setPreferredHospital(e.target.value)}
        fullWidth
        style={{ marginBottom: "20px", backgroundColor: "white" }}
        className={classes.nameField}
        InputProps={{
          // style: {
          //   backgroundColor: "#fff",
          // },
          inputProps: {
            style: { marginTop: "7px", marginBottom: "-3px" },
          },
        }}
      />

      <TextField
        label="Preferred Treatment Location (Optional)"
        value={preferredLocation}
        onChange={(e) => setPreferredLocation(e.target.value)}
        fullWidth
        style={{ marginBottom: "20px" }}
        className={classes.nameField}
        InputProps={{
          // style: {
          //   backgroundColor: "#fff",
          // },
          inputProps: {
            style: { marginTop: "7px", marginBottom: "-3px" },
          },
        }}
      />
      <Button
        className={classes.nextButton}
        variant="contained"
        onClick={handleNext}
        fullWidth
        disabled={!isStep1Valid}
      >
        Next
      </Button>
    </>
  );

  const renderStep2 = () => (
    <>
      <FormControl variant="filled" fullWidth className={classes.nameField}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className="w-23"
            value={phoneCode} // Concatenate phoneCode and phone here
            onChange={handlePhoneCodeChange}
            classes={{ root: classes.root }}
            style={{ marginTop: "16px" }}
          >
            {countriesList &&
              countriesList.map((item, index) => (
                <MenuItem
                  value={item.code}
                  key={item.code + "-" + index}
                  classes={{ root: classes.menuItem }} // Apply the custom styles to the MenuItem
                >
                  {item.code}
                </MenuItem>
              ))}
          </Select>

          <InputLabel htmlFor="phoneNumber" style={{ marginLeft: "101px" }}>
            Phone Number*
          </InputLabel>
          <Input
            id="phoneNumber"
            name="phone"
            label="Phone Number"
            required
            value={phone}
            onChange={(e) => {
              const value = e.target.value;
              const allowedChars = /^[0-9]*$/; // Allow only digits

              if (allowedChars.test(value)) {
                setPhone(value.slice(0, 10)); // Limit the input to 10 digits
                setPhoneError(false); // Reset the error state
              } else {
                setPhoneError(true); // Set the error state if non-digit characters are entered
              }
            }}
            error={phoneError}
            style={{ flex: 1, marginLeft: 6, width: "100px" }}
            className={classes.nameField}
            InputProps={{
              style: {
                backgroundColor: "#fff",
              },
            }}
          />
        </div>
        {phoneError && (
          <FormHelperText error>
            Please enter a valid phone number.
          </FormHelperText>
        )}
      </FormControl>
      <TextField
        id="email"
        name="email"
        label="Email"
        variant="filled"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        required
        style={{ marginBottom: "20px", backgroundColor: "white" }}
        className={classes.nameField}
        inputProps={{
          pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
          title: "Please enter a valid email address",
        }}
        InputProps={{
          style: {
            backgroundColor: "#fff", // Set the background color here
          },
          inputProps: {
            style: { marginTop: "7px", marginBottom: "-3px" }, // Add padding to the left side of the input text
          },
        }}
      />
      <FormControl
        id="country"
        name="country"
        variant="filled"
        required
        fullWidth
        style={{ marginBottom: "20px", backgroundColor: "white" }}
        className={classes.nameField}
      >
        <InputLabel id="gender">Select your country</InputLabel>
        <Select
          labelId="country"
          id="country"
          className="w-23"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          classes={{ root: classes.root }}
          InputProps={{
            style: { backgroundColor: "#fff" },
            inputProps: { style: { maxWidth: "100px" } },
          }}
          MenuProps={{
            components: {
              MenuItem: (props) => (
                <MenuItem
                  {...props}
                  classes={{ root: classes.menuItem }}
                  style={{ maxWidth: "100px" }}
                >
                  {props.children}
                </MenuItem>
              ),
            },
          }}
        >
          {countriesList &&
            countriesList.map((item, index) => (
              <MenuItem value={item.name} key={`${item.name}-${index}`}>
                {item.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl
        variant="filled"
        style={{ marginBottom: "20px" }}
        fullWidth
        className={classes.nameField}
        classes={{ root: classes.root }} // Apply the custom styles
      >
        <InputLabel id="know">How Did you know about this</InputLabel>
        <Select
          labelId="know"
          id="know"
          name="know"
          value={know}
          onChange={(e) => setKnow(e.target.value)}
          classes={{ root: classes.root }} // Apply the custom styles
        >
          <MenuItem value="google search">Google Search</MenuItem>
          <MenuItem value="facebook">Facebook</MenuItem>
          <MenuItem value="friends/relatives">Friends/Relatives</MenuItem>
          <MenuItem value="past patients">Suggested by past patients</MenuItem>
          <MenuItem value="ambassador">Ambassador</MenuItem>
          <MenuItem value="other">Others</MenuItem>
        </Select>
      </FormControl>
      <TextField
        id="city"
        name="city"
        label="Enter your city"
        value={city}
        onChange={(e) => setCity(e.target.value)}
        variant="filled"
        required
        fullWidth
        style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
        className={classes.nameField}
        InputProps={{
          style: {
            backgroundColor: "#fff", // Set the background color here
          },
          inputProps: {
            style: { marginTop: "7px", marginBottom: "-3px" }, // Add padding to the left side of the input text
          },
        }}
      />
      <span
        style={{
          fontSize: "11px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        *We value your privacy, your details will be kept confidential. Refer
        our <a href="https://rxone.app/privacypolicy">Privacy Policy</a>
      </span>
      <div className={classes.recaptcha}>
        <ReCAPTCHA
          sitekey="6LddplcqAAAAACztSGsmgN9xckbNg_5mn4p4t3A5"
          onChange={handleRecaptchaChange}
        />
      </div>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        type="submit"
        fullWidth
        disabled={loading || !recaptchaValue}
      >
        {loading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          "Request Callback"
        )}
      </Button>
    </>
  );

  return (
    <>
      <Helmet>
        {" "}
        <title>International Patients</title>
      </Helmet>
      <Helmet>
        <meta
          name="description"
          content="RxOne.app offers comprehensive support for international patients looking for the best doctors, hospitals, and advanced treatments in India. Explore trusted healthcare options, personalized treatment plans, and world-class medical facilities tailored for global patients."
        />
      </Helmet>
      <Helmet>
        <meta
          name="keywords"
          content="international patients, best doctors in India, hospitals in India, medical treatment in India, healthcare in India, find doctors in India, RxOne.app, advanced treatment India, international patient care, medical tourism India"
        />
      </Helmet>

      <section
  data-aos-duration="3000"
  className="ms-cureways-section"
  style={{ position: "relative", minHeight: "120vh", overflow: "hidden" , marginTop:"50px"}}
>
  {/* Video Background Container */}
  {!isMobile && (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 0,
      }}
    >
    <iframe
    src="https://www.youtube.com/embed/Om-phsSDC8w?autoplay=1&mute=1&controls=0&loop=1&playlist=Om-phsSDC8w&showinfo=0&rel=0&modestbranding=1&iv_load_policy=3&enablejsapi=1&disablekb=1"
    style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        border: "none",
        pointerEvents: "none",
        objectFit: "cover",
        transform: "scale(1.5)",
    }}
    title="Care Continuum Video"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
    playsInline
    allowFullScreen
/>

      {/* Video Overlay */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1,
        }}
      />
    </div>
  )}

  {/* Content Container */}
  <div
    style={{
      position: "relative",
      zIndex: 2,
      padding: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: isMobile ? "column" : "row",
      minHeight: "100vh",
    }}
  >
    {/* Left Side Content */}
    <div style={{ flex: 1, order: isMobile ? 0 : 1 }}>
      <h1
        className="animate-title ms-font-weight-800"
        style={{
          color: "#fff",
          fontSize: isMobile ? "42px" : "3.5rem",
          fontWeight: "800",
          fontFamily: "inherit",
          textAlign: "center",
          opacity: 0,
          animation: "fadeInSlideUp 10s ease infinite",
          marginBottom: isMobile ? "1rem" : "0"
        }}
      >
        Care Continuum
      </h1>

      <div className="mb-3" style={{ textAlign: "center" }}>
        <p
          className="animate-globally fw-normal"
          style={{
            color: isMobile ? "yellow" : "#018c92",
            fontSize: isMobile ? "42px" : "3.5rem",
            fontWeight: "800",
            textAlign: "center",
            fontFamily: "inherit",
            opacity: 0,
            animation: "fadeInSlideUp 10s ease infinite",
            marginBottom: isMobile ? "2rem" : "0",
            marginLeft: isMobile ?  "0rem" : "",
          }}
        >
          GLOBALLY
        </p>
      </div>

      {/* Mobile Image */}
      {isMobile && (
        <div style={{ display: "flex", justifyContent: "center", order: 2 }}>
          <img 
            src={worldgif}
            alt="Care Continuum Globally"
            style={{
              width: "117%",
              height: "auto",
              marginTop: "2%"
            }}
          />
        </div>
      )}
    </div>

    {/* Right Side Content */}
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        order: isMobile ? 3 : 2
      }}
    >
      {/* WhatsApp Button */}
      <div className="whatsapp-block" style={{ marginBottom: "2rem" }}>
        
      <a
                href="https://api.whatsapp.com/send?phone=+917703934446&text=Hello%20RxOne,%20Could%20you%20please%20call%20back."
                target="_blank"
                rel="noopener noreferrer"
              >
          <img src={whatsapp} alt="whtsp" />
        </a>
      </div>

      {/* Contact Form */}
      <div ref={contactRef} style={{ width: isMobile ? "100%" : "auto" }}>
        <Container component="main" maxWidth="xs">
          <div
            className="provider_revolutionize_item"
            style={{
              backgroundColor: "#fff",
              width: "100%",
              borderRadius: "55px",
              boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 16px",
              position: "relative",
              padding: "2rem",
            }}
          >
            {step === 2 && (
              <ArrowBackIosNewIcon
                className="arrowIcon"
                onClick={handleBack}
              />
            )}
            <h4
              className="ms-font-weight-600 mb-4 ms-primary-color"
              style={{ textAlign: "center" }}
            >
              Contact Us
            </h4>
            <form
              style={{ textAlign: "center", width: "100%" }}
              onSubmit={handlesubmit}
            >
              {step === 1 ? renderStep1() : renderStep2()}
            </form>
          </div>
        </Container>
      </div>
    </div>
  </div>
</section>

      {showAlert && (
        <CenteredAlert
          message="We have received your request, please allow us up-to 24 Hrs. to come back to you."
          onClose={() => setShowAlert(false)}
        />
      )}

      <section className="info-section">
      <Treatmentmini/>
      <div className="vd"style={{marginBottom:"30px",marginTop: isMobile ? "-5px" : "-10px",}}>
      <section data-aos-duration="3000" className="ms-cureways-section">
        <div className="container" style={{ marginTop: "-8rem" }}>
          <div className="row align-items-center mb-5 g-5 flex-column-reverse margin__first flex-lg-row">
            <div className="col-lg-6">
              <h1
                className="mb-4 ms-font-weight-600"
                style={{ color: "#fff", marginTop:"20px"}}
              >
              Trusted Care
              </h1>
            </div>
          </div>
        </div>
        <div className="container" style={{ marginTop: "-10rem" }}>
          <div className="row align-items-center mb-5 g-5 flex-column-reverse margin__first flex-lg-row">
            <div className="col-lg-6">
              <h2
                className="ms-font-weight-500"
                style={{
                  color: "#fff",
                  fontSize: isMobile ? "1.5rem" : "3.5rem", // Adjust font size based on screen size
                  width:"100%",
                }}
              >
               We enable top-notch medical care at NABH,JCI hospitals at fraction of the cost          </h2>
               <a
        onClick={scrollToContact}
        style={{ textDecoration: "none", cursor: "pointer" }}
      >
        <div
          style={{
            display: "inline-block",
            border: "1px solid #fff",
            padding: "5px 10px",
            borderRadius: "12px",
          }}
        >
          <h3
            className="ms-font-weight-600"
            style={{ color: "#fff", margin: 0 }}
          >
            Contact Us
          </h3>
        </div>
      </a>

              {/* <div style={{marginTop:"20px"}}>
              <a href="https://calendly.com/careconsult_online" className="btn btn-light">Book A Demo</a></div> */}
            </div>
            <div className="col-lg-6">
            <div
                    style={{
                      position: "relative",
                      paddingBottom: "56.25%",
                      height: 0,
                      overflow: "hidden",
                      maxWidth: "100%",
                      borderRadius: "15px",
                      boxShadow:
                        "0 25px 25px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    }}
                  >
                   <iframe
    style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "15px",
        border: "none",  // Ensures no visible border
        pointerEvents: "none", // Prevents interactions
        objectFit: "cover",  // Ensures video scales nicely
    }}
    src="https://www.youtube.com/embed/G6CwgwnhfXs?autoplay=1&mute=1&loop=1&playlist=G6CwgwnhfXs&controls=0&showinfo=0&rel=0&modestbranding=1&iv_load_policy=3&disablekb=1"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    playsInline
    allowFullScreen
></iframe>

  </div>
</div>


          </div>
        </div>
      </section>
      </div>
    
        <div className="info-container">
          <h2>International Patients Services </h2>
          <p>
            Our care team ensures providing you with world class quality of
            service to make your medical journey hassle free for you and your
            family.
          </p>
          <div className="box-container">
            <div className="box">
              <AddAlarmIcon />
              <h4>Get a peronalized treatment plan and estimate</h4>
            </div>
            <div className="box">
              <AddCardIcon />
              <h4>Visa assistance</h4>
            </div>
            <div className="box">
              <LuggageIcon />
              <h4>Airport pickup and Drop</h4>
            </div>
            <div className="box">
              <PeopleIcon />
              <h4>Relationship Manager</h4>
            </div>
            <div className="box">
              <ApartmentIcon />
              <h4>Accommodation Food ,</h4>
            </div>
            <div className="box">
              <WheelchairPickupIcon />
              <h4>Discharge Departure , Post Hospitalization care</h4>
            </div>
          </div>
        </div>
      </section>
    
      <section className="circle-section">
        <div className="circle-container">
          <div className="headrec">
            <h2>How it works?</h2>
          </div>
          <div className="outer-circles">
            <div className="circle">
              General<br></br> Enquiry
            </div>
            <div className="arrow"></div>{" "}
            {/* Arrow pointing to the next circle */}
            <div className="circle">Medical opinion by experts</div>
            <div className="arrow"></div>
            <div className="circle">
              Visa <br></br>assistance
            </div>
            <div className="arrow"></div>
            <div className="circle">
              Booking <br></br>Arrival
            </div>
            <div className="arrow"></div>
            <div className="circle">Pre-Operative Consultation</div>
            <div className="arrow"></div>
            <div className="circle">Treatment</div>
            <div className="arrow"></div>
            <div className="circle">Recuperation</div>
            <div className="arrow"></div>
            <div className="circle">Post-Treatment Care</div>
          </div>
        </div>
      </section>
      
      <h5
        className="mb-4 ms-font-weight-600 ms-primary-color"
        style={{ marginLeft: "1rem", fontSize: "1.4rem" }}
      >
        Frequently Asked Questions
      </h5>

      <ul style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
        {Inter.map((Inter) => (
          <li className="languages card" key={Inter.id}>
            <div>
              <strong>{Inter.question}</strong>
              <button
                onClick={() => toggleQuestion(Inter.id)}
                style={{
                  border: "none",
                  background: "none",
                  display: "flex",
                  justifyContent: "end",
                  width: "100%",
                  marginTop: "-22px",
                }}
              >
                <span>{expandedQuestions.includes(Inter.id) ? "▲" : "▼"}</span>
              </button>
            </div>
            {expandedQuestions.includes(Inter.id) && <p>{Inter.answer}</p>}
          </li>
        ))}
      </ul>
    </>
  );
};
export default Internationalpage;