import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Android, Apple } from "@mui/icons-material"; // Import MUI icons
import downloadmobile from "../Images/home/RxOne-Splash_Screen.png";

const DownloadApp = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-center min-vh-100"
      style={{
        backgroundColor: "#fff",
        paddingTop: "80px", // Prevents overlap with header
        paddingBottom: "40px",
      }}
    >
      <div
        className="container p-4 shadow-lg rounded-4"
        style={{
          maxWidth: "1000px",
          backgroundColor: "#f8f9fa",
        }}
      >
        <div className="row align-items-center text-center text-md-start py-5">
          {/* Left Side - App Image */}
          <div className="col-md-6 mb-4 mb-md-0">
            <img
              src={downloadmobile}
              alt="Mobile App"
              className="img-fluid rounded-4 shadow-sm"
              style={{ maxWidth: "80%" }}
            />
          </div>

          {/* Right Side - Text & Download Buttons */}
          <div className="col-md-6">
            <h2 className="fw-bold" style={{ color: "rgb(0, 92, 116)" }}>
              Download Now
            </h2>
            <p className="text-muted mb-4">
              Get seamless healthcare services at your fingertips. Available for both Android & iOS.
            </p>

            {/* App Store & Play Store Buttons */}
            <div className="d-flex flex-row flex-wrap justify-content-center justify-content-md-start gap-2 align-items-center">
  {/* Google Play Store Button */}
  <a
    href="https://play.google.com/store/apps/details?id=com.rxone&hl=en_IN"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img
      src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
      alt="Get it on Google Play"
      style={{ height: "50px", width: "auto" }}
    />
  </a>

  {/* Apple App Store Button */}
  <a
    href="https://apps.apple.com/in/app/rxone-care-companion/id6479252685"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img
      src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
      alt="Download on the App Store"
      style={{ height: "50px", width: "auto" }}
    />
  </a>
</div>




          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
