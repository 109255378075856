import Header from './components/Header';
import { useEffect, useState } from 'react';
import Terms from "./components/CompanyPage/Terms"
import { BrowserRouter,Routes,Router, Route  } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';
import './App.css';
import Providers from './components/Providers';
import Booking from './components/Booking';
import AboutUs from "./components/AboutUs"
import SignUp from "./components/user/SignUp";
import Home from './components/Home';
import Home1 from './components/Home1';
import Footer from './components/Footer';
import ScrollToTop from './ScrollToTop';
import ContectUs from './components/ContectUs';
import PrivacyPolicy from "./components/CompanyPage/PrivacyPolicy"
import 'bootstrap/dist/css/bootstrap.min.css';
import Blogs from './components/Blogs';
import { getByDisplayValue } from '@testing-library/react';
import BlogPost from './components/BlogPost';
import VerifyBot from './components/chatBot/VerifyBot';
import ErrorBoundary from "./GlobelContext/ErrorBoundary"
import HospitalPath from './HospitalPath';
import ErrorPage from './ErrorPage';
import ServiceCall from './ServiceCall';
import Callexist from './components/Callexist';
// import Doctorprofile from './components/Doctorprofile';
import Cureways from './components/Cureways';
import CookiConsent from "react-cookie-consent"
import Patientregis from './components/Patientregis';
import Medical from './components/Medical';
import Medine from './components/Medicine';
import Medicalloan from './components/Medicalloan';
import Prescription from './components/Prescritpion';
import Internationalpage from './components/Internationalpage';
import Treatment from './components/Treatment';
import TreatmentHome from './components/Treatmenthome';
import HospitalsPage from './components/Hospitalpage';
import Doctoreprofilee from './components/Doctoreprofilee';
import Booking2 from './components/Booking2';
import Oldcare from './components/Oldcare';
import Header2 from './components/Header2';
import Joinus from './components/Joinus';
import DownloadApp from './components/DownloadApp';
function App() {
  const [patientPortal, setPatientPortal] = useState([]);
  const [doctorPortal, setDoctorPortal] = useState([]);
  const [headerFooter, setHeaderFooter] = useState(true);
  const [acceptedCookies, setAcceptedCookies] = useState(false);

  const shouldRenderHeaderFooter = !window.location.pathname.includes('/video/end/call') && 
                                    !window.location.pathname.includes('/RXONES');

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (cookiesAccepted) {
      setAcceptedCookies(true);
    }
  }, []);

    // Disable right click across the entire application
    useEffect(() => {
      const handleContextMenu = (e) => {
        e.preventDefault();
      };
      document.addEventListener('contextmenu', handleContextMenu);
      
      // Cleanup listener on component unmount
      return () => {
        document.removeEventListener('contextmenu', handleContextMenu);
      };
    }, []);

    useEffect(() => {
      const handleCopy = (e) => {
        e.preventDefault();
        e.clipboardData.setData('text', '');
      };
    
      document.addEventListener('copy', handleCopy);
    
      return () => {
        document.removeEventListener('copy', handleCopy);
      };
    }, []);

    useEffect(() => {
      const handleInspect = (e) => {
        if (e.keyCode === 123 || (e.ctrlKey && e.shiftKey && e.keyCode === 73)) {
          e.preventDefault();
          e.stopPropagation();
          return false;
        }
      };
    
      document.addEventListener('keydown', handleInspect);
    
      return () => {
        document.removeEventListener('keydown', handleInspect);
      };
    }, []);
    

  const handleAcceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setAcceptedCookies(false);
  };

  return (
    <BrowserRouter>
    <div className='user-select-none'>
      <div className='overflow-hidden'>
        <ScrollToTop />
        
        {/* Conditionally render header */}
        {shouldRenderHeaderFooter && <Header2 />}

        <Routes>
          <Route exact path="/" element={<Home1 />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path='/doctor/:id' element={<HospitalPath headerfooter={setHeaderFooter} path={doctorPortal} />} />
          <Route path="/providers" element={<Providers />} />
          {/* <Route path="/profile/:route_key" element={<Doctorprofile />} /> */}
          <Route path="/specialists/:code" element={<Booking />} />
        
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/contactus" element={<ContectUs />} />
          <Route path="/blogs/:slug" element={<Blogs />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/treatment-advice" element={<Patientregis />} />
          <Route path="/book-pathology-tests-india" element={<Medical />} />
          <Route path="/order-medicine-online-india" element={<Medine />} />
          <Route path="/medical-finance-loan-india" element={<Medicalloan />} />
          <Route path='/video/end/call' element={<Callexist />} />
          <Route path='/cureways/:code' element={<Cureways />} />
          <Route path="/RXONES/:code" element={<Prescription />} />
          <Route path="/international-patients" element={<Internationalpage />} />
          <Route path="/profile/:route_key" element={<Doctoreprofilee/>} />
          <Route path='/treatment' element={<TreatmentHome/>} />
          <Route path='/consultation' element={<Booking2/>} />
          <Route path='/treatments/:route_key' element={<Treatment/>} />
          <Route path='/hospitalpage/:hospital_route_key' element={<HospitalsPage/>} />
          <Route path="*" element={<ErrorPage headerfooter={setHeaderFooter} path={patientPortal} />} />
          <Route path='/devices/elderly-care/ripple-hippro' element={<Oldcare/>} />
          <Route path='/joinus' element={<Joinus/>} />
          <Route path='/download' element={<DownloadApp/>} />
        </Routes>

        {/* Conditionally render footer */}
        {shouldRenderHeaderFooter && <Footer />}

        {/* Cookie consent component */}
        {!acceptedCookies && (
          <CookiConsent
            debug={true}
            location='bottom'
            style={{ backgroundColor: "#005c78", textAlign: "left", fontSize: "14px" }}
            buttonStyle={{ color: "000", background: "#fff", fontSize: "15px" }}
            buttonText="Accept all cookies"
            onAccept={handleAcceptCookies}
            cookieName="cookieConsent"
          >
            By clicking the Allow Cookies button you agree to the use of cookies as described in our Privacy Policy 
            <a href="https://rxone.app/privacypolicy" target="_blank" rel="noreferrer">Len more..</a>
          </CookiConsent>
        )}
      </div>
      </div>
    </BrowserRouter>
  );
}

export default App;


// export default App;

// divya.kaushik@atcs.com